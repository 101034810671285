import 'whatwg-fetch';
import Promise from 'promise-polyfill';

class Corsair {
  constructor() {
    this.proxyEndpointBase = 'https://corsair.yextpages.net/proxy?url=';

    // Promise polyfill for IE, whatwg-fetch includes polyfill for fetch
    if (!window.Promise) {
      window.Promise = Promise;
    }
  }

  proxyUrl(url) {
    return `${this.proxyEndpointBase}`+ encodeURIComponent(url);
  }

  buildAssetList(urls) {
    return urls.map(this.proxyUrl.bind(this));
  }

  fetch(urls) {
    let assetUrls = [];

    // Check if fetch parameter is single URL or array of URLs
    if (typeof(urls) === 'string' || urls instanceof String) {
      assetUrls.push(this.proxyUrl(urls));
    } else {
      assetUrls = this.buildAssetList(urls);
    }

    let assetPromises = assetUrls.map((assetUrl) => {
      return fetch(assetUrl);
    });

    return Promise.all(assetPromises);
  }
}

const Instance = new Corsair();

export {
  Instance,
  Corsair as Proxy
}
