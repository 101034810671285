import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';

// uncomment if you need to do expand/collapse:
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { Yext } from 'js/components/Soy2js/Soy2js.js'; // NOTE: if not using soy2js, replace with window.Yext = window.Yext || {};

import { DistanceCalculator } from 'ts/components/DistanceCalculator/index.ts'; /* TODO: remove if unused */
import { Sniffer } from 'ts/components/Sniffer/index.ts'; /* TODO: remove if unused */

import { Cookies } from 'js/components/Cookies/index.js'; /* TODO: remove if unused */
import { Corsair } from 'js/components/Corsair/index.js'; /* TODO: remove if unused */
import { Description } from 'js/components/Description/index.js'; /* TODO: remove if unused */
import { Events } from 'js/components/Events/index.js'; /* TODO: remove if unused */
import { GoogleAdwords } from 'js/components/GoogleAdwords/index.js'; /* TODO: remove if unused */
import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js'; /* TODO: remove if unused */
import { Image } from 'js/components/Image/index.js'; /* TODO: remove if unused */
import { Instagram } from 'js/components/Instagram/index.js'; /* TODO: remove if unused */
import { LocationHours } from 'js/components/LocationHours/index.js'; /* TODO: remove if unused */
import { Maps } from 'js/components/Maps/index.js'; /* TODO: remove if unused */
import { Reviews } from 'js/components/Reviews/index.js'; /* TODO: remove if unused */
import { VectorMap } from 'js/components/VectorMap/index.js'; /* TODO: remove if unused */
import { YoutubeEmbed } from 'js/components/YoutubeEmbed/index.js'; /* TODO: remove if unused */

import { Instance as MapsAPI } from 'js/components/Maps/GoogleMapsAPI.js';
Yext.Maps = Maps

Global.init();
GoogleAdwords.init();
GoogleAnalytics.enableAutotracking('yext');

OnReady(() => {
  Maps.loadAndRun();
  LocationHours.loadAndRun();
  MapsAPI.load();

  // - The un-minified file is different than the minified one :/
  // - Uncomment the following if using JVector map
  // VectorMap.autorun();
});
