import 'script-loader!node_modules/jvectormap/jquery-jvectormap.min.js';
import './jquery-jvectormap-us-aea-en.js';

export class VectorMap {
  // You must call autorun manually
  static autorun(scope) {
    const queryScope = scope || document;
    this.instances = [];
    for (const el of queryScope.querySelectorAll('.VectorMap')) {
      el.yVectorMap = new VectorMap(el);
      VectorMap.instances.push(el);
    }
  }

  makeTextElement(type,contents) {
    const el = document.createElement(type);
    el.id = `${this.svgIDRoot()}-${type}`;
    const text = document.createTextNode(contents);
    el.appendChild(text);
    return el;
  }

  svgIDRoot() {
    return `${this.element.id}-map`;
  }

  makeAccessibile(mapContainerElement) {
    const skipMap = this.makeTextElement('a', 'Skip Map Navigation');
    skipMap.setAttribute('class', 'sr-only sr-only-focusable');
    skipMap.id = `${this.svgIDRoot()}-skip`;
    skipMap.href = `#${this.svgIDRoot()}-back`;
    const backToMap = this.makeTextElement('a', 'Back to Map Navigation');
    backToMap.href = `#${this.svgIDRoot()}-skip`;
    backToMap.id = `${this.svgIDRoot()}-back`;
    backToMap.setAttribute('class', 'sr-only sr-only-focusable');
    this.element.parentElement.insertBefore(skipMap, this.element);
    this.element.parentElement.insertBefore(backToMap, this.element.nextSibling);

    this.svg = mapContainerElement.querySelector('svg');

    const desc = this.makeTextElement('desc', this.mapDescription);
    const title = this.makeTextElement('title', this.mapTitle);
    this.svg.id = this.svgIDRoot();
    this.svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    this.svg.setAttribute('aria-labeledby', `${title.id} ${desc.id}`);
    this.svg.insertBefore(desc, this.svg.firstChild);
    this.svg.insertBefore(title, this.svg.firstChild);
    const existingClasses = this.svg.getAttribute('class');
    if (existingClasses) {
      this.svg.setAttribute('class', `${existingClasses} VectorMap-map`);
    } else {
      this.svg.setAttribute('class', 'VectorMap-map');
    }
    const pathGroup = this.svg.querySelector('g');

    const paths = Array.prototype.slice.call(pathGroup.querySelectorAll('path'));
    const changedElements = [];
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      const keyCode = path.getAttribute("data-code");
      const info = this.keyInfo[keyCode];
      if (info) {
        const link = document.createElementNS('http://www.w3.org/2000/svg', 'a');
        link.setAttribute('href', info.fullUrl);
        link.setAttribute('xlink:href', info.fullUrl);
        link.setAttribute('title', info.name);
        link.setAttribute('aria-label', info.name);
        link.setAttribute('class', 'VectorMap-link');
        link.appendChild(path);
        // fix click problem in safari
        link.addEventListener('click', (e) => {
          e.preventDefault();
          window.location.href=link.getAttribute('href');
        });
        changedElements.push(link);
      } else {
        changedElements.push(path);
        pathGroup.removeChild(path);
      }
    }
    for (let newPath of changedElements) {
      pathGroup.appendChild(newPath);
    }
  }

  constructor(element, opts) {
    this.element = element;
    if (opts == null) { opts = {}; }
    this.opts = opts;
    let prefix = $(this.element).data('prefix');
    if (prefix == null) {
      prefix = "";
    }

    let dataOpts = {
      id: `#${$(this.element).attr('id')}`,
      mapKey: $(this.element).data('key-name'),
      keyPrefix: prefix,
      dataSource: `#${$(this.element).data('source')}`,
      autoRender: $(this.element).data('auto-render')
    };

    this.mapTitle = this.element.dataset.mapTitle;
    this.mapDescription = this.element.dataset.mapDescription;
    this.mapLabel = this.element.dataset.mapLabel;

    this.opts = $.extend(dataOpts, this.opts);

    this.keys = JSON.parse($(this.opts.dataSource).text());
    this.vals = {};
    this.keyInfo = {};

    for (let key of Array.from(this.keys)) {
      const vectorKey = `${this.opts.keyPrefix}${key.key}`;
      this.vals[vectorKey] = 1;
      this.keyInfo[vectorKey] = key;
    }

    this.mapRenderConfig = {
      map: this.opts.mapKey,
      backgroundColor: 'none',
      zoomOnScroll: false,
      zoomButtons: false,
      regionStyle: {
        initial: {
          fill: this.opts.fillColor,
          stroke: this.opts.strokeColor,
          "stroke-width": .5,
          cursor: "pointer"
        },

        hover: {
          fill: this.opts.hoverFillColor,
          stroke: this.opts.hoverStrokeColor,
          "fill-opacity": 1
        }
      },

      series: {
        regions: [{
          values: this.vals
        }
        ]
      },



      onRegionTipShow(e, tip, code) {
        $('.jvectormap-tip', '.jvectormap-label').hide(); // Needed to prevent the tip and label from showing on hover
        e.preventDefault();
      },
    };

    this.renderMap = function() {
      $(this.opts.id).vectorMap(this.mapRenderConfig);
      this.makeAccessibile(document.querySelector(this.opts.id));
    };

    if (this.opts.autoRender) {
      this.renderMap();
    }
  }
}
