import { LoadScript } from 'js/components/performance/LoadContent.js';

// Random token obtained from `cat GoogleMapsAPILibraryCallbackMcGinn | md5`
const globalCallback = "GoogleMapsAPICallbackd41d8cd9";
const baseUrl = "https://maps.googleapis.com/maps/api/js";

class Loader {
  constructor(callbackScope, identifier) {
    this.libraries = [];
    this.params = {
      "callback": identifier
    }

    this.deferred = new Promise((resolve, reject) => {
      this.resolve = resolve;
    });

    callbackScope[identifier] = () => {
      this.callback();
    }
  }

  addLibrary(library) {
    if (this.loadInvoked) {
      console.log("addLibrary() called after load() - stack trace follows");
      console.trace && console.trace();
    }
    this.libraries.push(library)
  }

  setParam(k, v) {
    if (this.loadInvoked) {
      console.log("setParam() called after load() - stack trace follows");
      console.trace && console.trace();
    }
    this.params[k] = v
  }

  load() {
    if (this.loadInvoked) {
      console.log("load() called after load() - stack trace follows");
      console.trace && console.trace();
    }
    this.loadInvoked = true;

    let params = this.params;

    params["libraries"] = this.libraries.join(",")

    LoadScript(baseUrl + "?" +  Object.entries(params).map(([key, value]) => key + "=" + value).join("&"));
  }

  callback() {
    this.resolve();
  }

  loaded() {
    return this.deferred;
  }
}

const Instance = new Loader(window, globalCallback);
const LibraryTypes = {
  Places: "places"
}

export {
  Loader,
  Instance,
  LibraryTypes,
}
