import { MonitoringInit } from 'js/components/Monitoring/Monitoring.js';
import { Polyfills } from 'js/components/Polyfills/Polyfills.js';
import { Instance } from 'js/components/YextAnalytics/Analytics.js';
import { AccessibilityChecks } from 'js/components/Util/Accessibility.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { ImageObjectFit } from 'js/components/Image/index.js';
import { Header } from 'js/common/modules/Header.js';
import { Debug } from "ts/components/Util/Debug.ts";
import { Instance as WCAGNewTab } from "js/components/Util/WcagNewTab.js";
import { AnalyticsDebugger } from "ts/components/AnalyticsDebugger/AnalyticsDebugger.ts";
import { GeoSearchFormBinder }  from "js/components/Util/GeoSearchFormBinder.js";
import { MapboxTypeAhead } from 'js/components/Util/MapboxTypeAhead.js';

import 'script-loader!node_modules/svg4everybody/dist/svg4everybody.min.js';

export class Global {
  static init() {
    Polyfills.init();
    MonitoringInit();

    OnReady(() => {
      new Header();
      Yext.Analytics = Instance;

      ImageObjectFit();

      svg4everybody();
      WCAGNewTab.wcagify();

      // This is just annoying
      /* if (Yext.isStaging ||
          window.location.href.includes('yextpages') ||
          window.location.href.includes('localhost')) {
        AccessibilityChecks.checkAltTags();
      } */

      if (Debug.isEnabled()) {
        window.Debugger = new AnalyticsDebugger();
      }

      if (document.querySelector('.search-input') != null && document.querySelector('.js-geolocate') != null) {
        // Geolocation Button
        let searchFormBinder = new GeoSearchFormBinder(document.querySelector('.search-input'), document.querySelector('.search'));

        document.querySelector('.js-geolocate').addEventListener('click', function(e) {
            e.preventDefault();
            searchFormBinder.geolocateAndSearch();
        });
      }
      if (document.querySelector('.search-input') != null) {
        $('.search').on('submit', function(e) {
          e.preventDefault();
          if (document.querySelector('.js-map-config') != null) {
            let pq = JSON.parse($('.js-map-config')[0].innerText).query;
            let inputValue = $('.search-input')[0].value;
            if (pq == inputValue) {
              window.location.reload();
              return;
            } 
          }
          let country = document.querySelector('#query-country').innerText;
          this.q.value += `, ${country}`;
          this.submit();
          this.querySelector('.search-button').disabled = true;
        })
      }

      new MapboxTypeAhead({
        parentId: 'simple-search',
        inputId: 'q',
        globalSearch: true
      });
    });
  }
}