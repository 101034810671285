import { HTML5Geolocation } from './HTML5Geolocation.js';

let globalIdCounter = 0;

export class MapboxTypeAhead {
  static initAll() {
    Array.from(document.querySelectorAll('.MapboxTypeAhead-parent')).forEach((parent) => {
      if (!parent.id) {
        console.error('MapboxTypeAhead-parent must have an id', parent);
        return
      }
      const targetEl = parent.querySelector('.MapboxTypeAhead');
      if (!targetEl || !targetEl.id) {
        console.error('MapboxTypeAhead target must a child of the parent element and have an id', targetEl)
      }
      parent.TypeAhead = new MapboxTypeAhead({
        parentId: parent.id,
        inputId: targetEl.id
      })
    });
  }
  constructor(options) {
    if (!options.parentId || !options.inputId) {
      console.error("you must provide a parentId and inputId", options);
      return
    }
    Object.assign(this, options);
    this.accessToken = this.accessToken || 'pk.eyJ1IjoieWV4dCIsImEiOiJqNzVybUhnIn0.hTOO5A1yqfpN42-_z_GuLw';
    this.decodeMode = this.decodeMode || 'mapbox.places';
    this.globalSearch = this.globalSearch || false;
    this.country = this.country || 'us';
    this.limit = this.limit || 5;
    this.input = document.getElementById(this.inputId);
    this.input.setAttribute('autocomplete','off');
    this.selectOptions = [];
    this.geolocationBias = this.geolocationBias || false;
    if (this.geolocationBias) {
      HTML5Geolocation.getCurrentLocation((pos) => this.geoSuccess(pos), (error) => this.geoError(error));
    }

    this.ariaStatusSetup();
    this.appendScript();
    this.setProximityString(null);
  }

  autoCompleteData() {
    return {
      source: this.selectOptions,
      appendTo: `#${this.parentId}`,
      classes: { 'ui-autocomplete': 'autocomplete-styling' }
    };
  }

  geoSuccess (pos) {
    if (this.geolocationBias) {
      this.setProximityString(pos);
    }
  }

  geoError (err) {
    if (this.geolocationBias) {
      console.error(err);
      this.setProximityString(null);
    }
  }

  setProximityString(pos) {
    this.proximityString = "";
    if(pos && pos.latitude && pos.longitude)
      this.proximityString = "&proximity="+pos.latitude+"%2C"+pos.longitude;
  }

  checkPolyfill() {
    this.nativeDatalist = !!('list' in document.createElement('input')) && !!(document.createElement('datalist') && window.HTMLDataListElement);
    if (!this.nativeDatalist) {
      $(this.input).autocomplete(this.autoCompleteData());
    } else {
        this.datalistSetup();
    }
  }

  appendScript() {
    // TODO: refactor to use singleton to load these scripts and resolve a promise when loaded
    let href = 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/themes/smoothness/jquery-ui.css';
    let link = document.createElement('link');
    let scriptSrc = 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js';

    link.rel = 'stylesheet';
    link.href = href;
    document.body.appendChild(link);

    $.getScript(scriptSrc, (data, textStatus, jqxhr) => {
      if (jqxhr.status == 200) {
        this.constructorCallback();
      }
    });
  }

  constructorCallback() {
    this.checkPolyfill();

    let that = this;

    this.input.addEventListener('keyup', function(evt){
      let queryString = this.value;

      if (queryString.length != 0) {
        if (that.jqxhr && that.jqxhr.readyState != 4) {
          that.jqxhr.abort();
        }

        let apiQuery = `https://api.mapbox.com/geocoding/v5/${that.decodeMode}/${queryString}.json?access_token=${that.accessToken}&limit=${that.limit}${that.proximityString}`;

        if (!that.globalSearch) {
          apiQuery += `&country=${that.country}`;
        }

        that.jqxhr = $.get(apiQuery)
         .done(function(data) {
           that.setOptions(data);
         })
         .fail(function(){
           that.pruneOptions();
         });
      }
    });
  }

  datalistSetup() {
    this.dataList = document.createElement('datalist');
    this.dataList.id = `mapbox-typeahead-datalist-${++globalIdCounter}`;
    this.dataList.className = 'mapbox-typeahead';

    this.input.setAttribute('list', this.dataList.id);
    $(this.dataList).insertAfter(this.input)
  }

  ariaStatusSetup() {
    this.status = document.createElement('span');
    this.status.id = 'aria-status';
    this.status.className = 'sr-only';
    this.status.setAttribute('aria-live', 'polite');
    this.status.innerHTML = 'Type to begin querying for matching results';

    $(this.status).insertAfter(this.input);
  }

  setOptions(data) {
    this.pruneOptions();

    if (data && data.features.length) {
      let places = data.features;
      places.forEach(place => {
        if (place.place_name) {
          this.selectOptions.push(place.place_name);
        }
      });

      if (!this.nativeDatalist) {
        $(this.input).autocomplete(this.autoCompleteData());
      } else {
        if (this.selectOptions.length > 0) {
          this.selectOptions.forEach(choice => {
            let option = document.createElement('option');
            option.value = choice;
            this.dataList.appendChild(option);
          });
        }
      }
    }

    if (this.selectOptions.length > 1)
    {
      this.status.innerHTML = `${this.selectOptions.length} options available. Use up and down arrow keys to navigate.`;
    }
    else if (this.selectOptions.length == 1)
    {
      this.status.innerHTML = '1 option available. Use up and down arrow keys to navigate.';
    }
    else
    {
      this.status.innerHTML = 'No results found for this query.';
    }
  }

  pruneOptions() {
    this.selectOptions = [];

    if (!this.nativeDatalist) {
      $(this.input).autocomplete(this.autoCompleteData());
    } else {
      this.dataList.innerHTML = '';
    }
  }
}
